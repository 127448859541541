<template>
    <div class="action-alimi-basic card">
        <div v-if="content.url" class="img-cover" v-img-cover="content.url" @click="onClickAlimiImage" />
        <div class="title" v-html="content.title" />
        <div class="body">
            <div ref="msg" class="msg" :class="{ expanded: expanded }" v-html="content.msg" />
            <div
                v-if="shouldShowLoadMore"
                @click="expand"
                class="btn-load-more flex-wrap"
                v-html="$translate('MORE')"
            />
            <button
                v-if="content.cta"
                @click="onClickAlimiCta"
                class="btn btn-alimi"
                :class="content.cta_class"
                v-html="content.cta"
            />
        </div>
    </div>
</template>
<script>
import { isStackRoute } from '@/router/stack-router'

export default {
    name: 'ActionAlimiBasic',
    props: ['message'],
    data: () => ({
        shouldShowLoadMore: false,
        dom: null,
        expanded: false,
    }),
    mounted() {
        this.dom = this.$refs.msg
        if (!this.dom) return

        // 4줄까지의 scrollHeight = 76px
        this.shouldShowLoadMore = this.dom.scrollHeight > 76
    },
    computed: {
        content() {
            return this.$mustParse(this.message.content.replace(/=>/g, ':')) || {}
        },
    },
    methods: {
        onClickAlimiImage() {
            if (this.content.action.includes('http://') || this.content.action.includes('https://'))
                this.onClickAlimiCta()
        },
        async onClickAlimiCta() {
            if (!this.content.action) return

            if (this.content.action.includes('http://') || this.content.action.includes('https://')) {
                this.$nativeBridge.postMessage({
                    action: 'openInAppBrowser',
                    value: this.content.action,
                })
                return
            }

            // Do whatever this.content.action denotes
            switch (this.content.action) {
                case 'chats': {
                    // 기존에 chat_id 였는데 action_id로 통일
                    const chat = (this.$store.getters.chats || []).find(
                        c => c.id === this.content.chat_id || c.id === this.content.action_id
                    )
                    if (chat !== null) {
                        await this.$store.dispatch('loadChat', {
                            chatId: chat.id,
                        })
                        this.$router.push({ name: 'ChatroomPage' })
                    } else {
                        this.$toast.error('대화방이 없습니다.')
                    }
                    break
                }
                case 'real_friends': {
                    this.$router.push({ name: 'RealFriendMeetingPage' })
                    break
                }
                default:
                    isStackRoute(this.content.action)
                        ? this.$stackRouter.push({ name: this.content.action })
                        : this.$router.push({ name: this.content.action })
                    break
            }
        },
        expand() {
            this.expanded = true
            this.shouldShowLoadMore = false
        },
    },
}
</script>
